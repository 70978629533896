import axios from "axios";

export const customFetch = async (url: any, options: any) => {
    const response = await axios.request({
        url,
        method: options.method,
        headers: options.headers,
        onUploadProgress: options.onUploadProgress,
        data: JSON.parse(options?.body),
    });

    return {
        ok: true,
        text: () => Promise.resolve(JSON.stringify(response.data)),
    } as any;
};
